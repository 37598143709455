<template>
  <div class="p-fluid grid">
    <div class="col-12 sm:col-5 md:col-4 lg:col-3">
      <pv-input label="ID・ラベル" v-model="cond.word" float full icon="search" />
    </div>
    <div class="col-fixed">
      <pv-calendar label="最終登録日" v-model="cond.created_at" format="yy/mm/dd" float icon />
    </div>
    <div class="col-fixed field">
      <pv-dropdown class="ml-1" v-model="cond.result" :options="SubjectResults" optionLabel="label" optionValue="value" placeholder="判定結果">
        <template #value="{value}">
          <i v-if="value == 'low'" class="pi pi-sun text-pink-500 ml-2"></i>
          <i v-else-if="value == 'middle'" class="pi pi-cloud text-gray-400 ml-2"></i>
          <i v-else-if="value == 'high'" class="pi pi-bolt text-yellow-500 ml-2"></i>
        </template>
        <template #option="{option}">
          <i v-if="option.value == 'low'" class="pi pi-sun text-pink-500"></i>
          <i v-else-if="option.value == 'middle'" class="pi pi-cloud text-gray-400"></i>
          <i v-else-if="option.value == 'high'" class="pi pi-bolt text-yellow-500"></i>
        </template>
      </pv-dropdown>
    </div>
  </div>
  <div class="p-fluid grid">
    <div class="col-12 md:col-6 lg:col-4 subject" v-for="sub in subjects" :key="sub.id">
      <div class="flex justify-content-start flex-wrap">
        <pv-button v-if="sub.voice.result == 1" icon="pi pi-sun" class="p-button-rounded p-button-danger cursor-auto" />
        <pv-button v-else-if="sub.voice.result == 2" icon="pi pi-cloud" class="p-button-rounded p-button-secondary cursor-auto" />
        <pv-button v-else-if="sub.voice.result == 3" icon="pi pi-bolt" class="p-button-rounded p-button-warning cursor-auto" />
        <pv-button v-else icon="pi pi-question" class="p-button-rounded p-button-secondary p-button-outlined cursor-auto" />
        <template v-if="labels.length > 0">
          <div v-for="l in labels" :key="l.id" class="m-2">
            {{ getAttr(sub.attrs, l)?.value }}
          </div>
        </template>
        <div v-else class="m-2">
          {{ sub.voice?.filename }}
        </div>
      </div>
      <div class="flex justify-content-between">
        <div>{{ dayjs(sub.created_at).format("YYYY/MM/DD HH:mm") }}</div>
        <div class="flex justify-content-end">
          <pv-button class="ml-2 p-button-text" icon="pi pi-pencil" @click="editSubject(sub)" />
          <pv-button class="ml-2 p-button-warning p-button-text" icon="pi pi-cloud-upload" @click="$router.push(`/staff/upload/pc/${sub.id}`)" />
          <pv-button class="ml-2 p-button-danger p-button-text" icon="pi pi-trash" @click="removeSubject(sub)" />
          <pv-button v-if="sub.playing" class="ml-2 p-button-success p-button-text" icon="pi pi-stop" @click="sub.pause" />
          <pv-button v-else class="ml-2 p-button-success  p-button-text" icon="pi pi-play" @click="playSubject(sub)" />
        </div>
      </div>
      <pv-divider />
    </div>

    <pv-speed-dial direction="down-right" class="p-button-raised" @click="subjectDialog = true" />

    <subject-dialog v-model="subjectDialog" :subject="subject" @change="loadSubjects(subjectParams)" />

    <subject-delete-dialog v-model="confirmDelete" :subject="delSubject" @delete="loadSubjects(subjectParams)" />
  </div>
</template>

<style scoped>
.subject {
  padding: 10px;
}
::v-deep(.p-float-label) {
  margin-top: 0;
}

.icon-left {
  font-size: 15px;
  margin: auto 10px;
}
::v-deep(.p-calendar) {
  width: 150px;
}
::v-deep(.p-speeddial-direction-down-right) {
  position: fixed;
  right: 16px;
  bottom: 16px;
}
</style>

<script>
import { ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getSubjects, useClientLabels, useSubject } from "@/common/api";
import { SubjectResults } from "@/common/master";
import SubjectDialog from "@/components/SubjectDialog.vue";
import SubjectDeleteDialog from "@/components/SubjectDeleteDialog.vue";
import dayjs from "dayjs";

export default {
  layout: "staff",
  components: {
    SubjectDialog,
    SubjectDeleteDialog,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const subjects = ref([]);
    const subjectParams = ref({ sortField: "created_at", sortOrder: -1 });
    const { labels, getAttr } = useClientLabels();

    const cond = ref({ result: "" });

    watch(() => cond.value, async () => {
      await loadSubjects(subjectParams.value);
    }, { deep: true });

    async function loadSubjects(params) {
      if (!store.getters.isLogin) return;
      params.created_at = cond.value.created_at ? dayjs(cond.value.created_at).format("YYYY-MM-DD") : "";
      params.word = cond.value.word;
      params.result = cond.value.result;
      subjectParams.value = params;
      let data = await getSubjects(params);

      subjects.value = data.subjects;
    }
    watchEffect(async () => {
      await loadSubjects({
        first: 0,
        rows: 10,
        sortField: "created_at",
        sortOrder: -1,
      });
    });

    const { subject, subjectDialog, editSubject, delSubject, confirmDelete, removeSubject, playSubject } = useSubject();

    watch(() => subjectDialog.value, (dialog) => {
      if (!dialog) {
        subject.value = {};
      }
    });

    if (store.state.me?.user?.client_id == 4) {
      router.push("/staff/upload/40");
    }
    else if (store.state.me?.user?.client_id == 5) {
      router.push("/staff/upload/pc/45");
    }

    return {
      subjects, labels, cond, getAttr, SubjectResults, subjectParams, loadSubjects,
      subject, subjectDialog, editSubject, delSubject, confirmDelete, removeSubject, playSubject,
    };
  }
}
</script>